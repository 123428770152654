
import { template, templateSettings } from "lodash";
import { Vue, Options, Prop } from "vue-property-decorator";
import { ErrorObject } from "@vuelidate/core";

// Curly brace syntax
templateSettings.interpolate = /{{([\s\S]+?)}}/g;

// This would most likely be in a language file
const TEMPLATES_MAP: any = {
  alpha: "Alphanumeric characters only.",
  minValue: "Must have a length no less than {{min}}.",
  maxValue: "Must have a length less then than {{max}}.",
  minLength: "Bitten geben Sie einen Wert mit einer Länge von mind. {{min}} Zeichen ein.",
  maxLength: "Must have a length less then than {{max}}.",
  required: "Pflichtfeld",
  email: "Enter a valid email",
  passwordConfirmation: "Passwords must be identical.",
  zipCode: "Zip code is not valid for country {{value}}.",
  pdfRequired: "File must be PDF.",
  numeric: "Must be numeric",
};
@Options({})
export default class Validation extends Vue {
  public name = "Validation";

  @Prop({ required: false, default: false })
  public showErrors!: any;

  @Prop({ required: true })
  public validations!: any;

  public get errors(): Array<string> {
    if (!this.invalid) {
      return [];
    }
    return this.validations.$errors.reduce((errors: Array<string>, validator: ErrorObject) => {
      const compiled = template(TEMPLATES_MAP[validator.$validator]);
      //@ts-ignore
      const error = compiled(this.validations[validator.$validator].$params);
      errors.push(error);
      return errors;
    }, []);
  }

  public get invalid(): boolean {
    return this.validations.$dirty && this.validations.$invalid;
  }
}
